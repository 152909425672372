import React, { useState, useEffect, useRef } from "react";
import "../../../style/questionnaire/TextArea.css";

import { getRandomText } from "../../../utils/HelperFunctions";

export interface TextAreaProps {
  style?: string;
  text?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  addClass?: string;
  values?: string | number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  errors?: string | Array<string> | any;
  id?: string;
  size?: "default" | "small" | "medium" | "large";
  rows?: number | undefined;
  fixedSize?: boolean;
  AutoGrow?: boolean;
  helperText?: string;
  isReadOnly?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  label,
  placeholder,
  disabled,
  addClass,
  name,
  isReadOnly,
  errors,
  onBlur,
  values,
  id = getRandomText(10),
  rows = 1,
  onChange,
  AutoGrow,
  onKeyDown,
}: TextAreaProps) => {
  //  Auto Growing Textarea Start //
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [textAreaValue, setTextAreaValue] = useState<String>();

  useEffect(() => {
    if (textareaRef && textareaRef.current && AutoGrow) {
      textareaRef.current.style.height = `${1 * rows}px`;
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 1 + "px";
    }
  }, [textAreaValue]);

  const handleChange = (event: any) => {
    onChange(event);
    if (AutoGrow) {
      setTextAreaValue(event.target.value);
    }
  };
  //  Auto Growing Textarea End //
  return (
    <div className="textarea-wrap">
      <div className="input-group">
        {label && (
          <label htmlFor={id}>
            {label}
            <span className="sr-only">dummy</span>
          </label>
        )}
        <textarea
          onKeyDown={onKeyDown}
          id={id}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          name={name}
          className={addClass}
          onBlur={onBlur}
          value={values}
          rows={rows}
          ref={textareaRef}
          readOnly={isReadOnly}
        />
        {errors && <div className="form-errors-msg">{errors}</div>}
      </div>
    </div>
  );
};

export default React.memo(TextArea);
