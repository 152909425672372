import axios, { AxiosError, AxiosResponse } from "axios";
import {
  TOKEN_KEY,
  getCookie,
  removeCookie,
  setCookie,
} from "../utils/useCookie";

// Skip authorization token for endpoints
const skipAuthorization = [
  "signin",
  "staff-login",
  "newuser",
  "two-step-authentication",
  "reset-password",
  "twoFactorAuthenticationByEmail",
  "twoFactorAuthenticationBySms",
  "newUserverifybyemail",
  "newUserverifybysms",
  "newUserverifybyemail",
  "eligibility",
  "otp-verification",
  "getAddressAutocomplete",
  "getAddressById",
];
// Do not redirect to the login page on error during signup.
const signupVerification = window.location.pathname.includes(
  "/signup/completeverification"
);

// Create api instance with base url
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}`,
});

// Handle Request on api
const requestInterceptor = (request: any) => {
  const path = request.url.split("/")[1];
  if (skipAuthorization.indexOf(path) === -1) {
    const accessToken = getCookie(TOKEN_KEY);
    // console.log("request interceptor :>>", accessToken);
    request.headers.Authorization = "Bearer " + accessToken;
    // request.headers["x-refresh"] = refreshToken;
  }
  return request;
};

// Handle Respose on every api
const responseInterceptor = (response: AxiosResponse) => {
  const newAccessToken: string = response.headers["x-access-token"];
  // console.log("response interceptor :>>", newAccessToken, response);
  if (newAccessToken) {
    setCookie(TOKEN_KEY, newAccessToken, { path: "/" });
  }
  return response.data;
};

// Handle Error Response
const ErrorResponseInterceptor = () => (error: AxiosError) => {
  // Check network status
  if (error.message === "Network Error") {
    return;
  }

  if (error.response) {
    // console.log("axios-err :>>",error);
    const { status, data }: any = error.response;
    const newAccessToken: string = error?.response.headers["x-access-token"];
    if (newAccessToken) {
      setCookie(TOKEN_KEY, newAccessToken, { path: "/" });
    }

    if (status === 401 && !signupVerification) {
      localStorage.removeItem("userDetails");
      localStorage.clear();
      removeCookie(TOKEN_KEY, { path: "/" });
      window.location.href = "/";
    }

    if (status === 401 && data.message === "Session Expired") {
      // Handle if the refresh token also expired and handle session expired
      localStorage.removeItem("userDetails");
      localStorage.clear();
      removeCookie(TOKEN_KEY, { path: "/" });
      window.location.href = "/session-expired";
    }
  }

  if (error.response) {
    return Promise.reject(error.response.data);
  }
};

// Request Interceptor
instance.interceptors.request.use(requestInterceptor);
// Response Interceptor
instance.interceptors.response.use(
  responseInterceptor,
  ErrorResponseInterceptor()
);

export default instance;
