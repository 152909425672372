import { createContext } from 'react';
export const PreviewContext = createContext({
  preview: { disabled: false },
  setPreview: (value: any) => {},
});

export const LockContext = createContext({
  lock: { disabled: false },
  setLock: (value: any) => {},
});
